import { createStore } from "vuex";
import * as Sentry from "@sentry/vue";
import measurementStore from "./measurement";

const language = window.navigator.language;
const SESSION_TIMEOUT = 4 * 60 * 1000; // min * s * ms

interface Session {
  timeout: NodeJS.Timeout | null;
  warning: boolean;
}

interface APIHeaders {
  "Content-Type": string;
  "X-USER-EMAIL": string | null;
  "X-USER-MONITORING-PROGRAM-CODE": string | null;
  "X-USER-TOKEN": string | null;
  "Accept-Language": string;
}

interface StoreState {
  measurements: Map<string, string>;
  tenantCode: string | null;
  accessToken: string | null;
  programCode: string | null;
  currentEpisodeId: string | null;
  userId: string | null;
  email: string | null;
  name: string | null;
  measurementStartDate: string | null;
  measurementEndDate: string | null;
  eulaAccepted: boolean;
  authErrorMessage: string;
  session: Session;
  cableConnected: boolean;
  headers: APIHeaders;
  // TODO: Add MeasurmentFormFields
}

export const store = createStore<StoreState>({
  state: {
    measurements: new Map(),
    tenantCode: localStorage.tenantCode,
    accessToken: localStorage.accessToken,
    programCode: localStorage.programCode,
    currentEpisodeId: localStorage.currentEpisodeId,
    userId: localStorage.userId,
    email: localStorage.email,
    name: localStorage.name,
    measurementStartDate: localStorage.measurementStartDate,
    measurementEndDate: localStorage.measurementEndDate,
    eulaAccepted: JSON.parse(localStorage.eulaAccepted || "false"),
    authErrorMessage: "",
    session: {
      timeout: null,
      warning: false,
    },
    cableConnected: false,
    headers: {
      "Content-Type": "application/json",
      "X-USER-EMAIL": localStorage.email,
      "X-USER-MONITORING-PROGRAM-CODE": localStorage.programCode,
      "X-USER-TOKEN": localStorage.accessToken,
      "Accept-Language": language,
    },
  },

  getters: {
    patientName(state) {
      return state.name;
    },

    authErrorMessage(state) {
      return state.authErrorMessage;
    },

    measurementStartDate(state) {
      return state.measurementStartDate;
    },

    measurementEndDate(state) {
      return state.measurementEndDate;
    },

    currentEpisodeId(state) {
      return state.currentEpisodeId;
    },

    showSessionWarning(state) {
      return state.session.warning;
    },

    cableConnected(state) {
      return state.cableConnected;
    },
  },

  mutations: {
    logout(state) {
      localStorage.clear();
      state.accessToken = null;
      state.tenantCode = null;
      state.programCode = null;
      state.currentEpisodeId = null;
      state.userId = null;
      state.email = null;
      state.name = null;
      state.measurementStartDate = null;
      state.measurementEndDate = null;
      state.eulaAccepted = null;
      state.headers = {
        "Content-Type": "application/json",
        "X-USER-EMAIL": null,
        "X-USER-MONITORING-PROGRAM-CODE": null,
        "X-USER-TOKEN": null,
        "Accept-Language": language,
      };

      state.session.warning = false;
      if (state.session.timeout) {
        clearTimeout(state.session.timeout);
      }
    },

    login(state) {
      state.accessToken = localStorage.accessToken;
      state.tenantCode = localStorage.tenantCode;
      state.programCode = localStorage.programCode;
      state.userId = localStorage.userId;
      state.email = localStorage.email;
      state.name = localStorage.name;
      state.measurementStartDate = localStorage.measurementStartDate;
      state.measurementEndDate = localStorage.measurementEndDate;
      state.authErrorMessage = null;
      state.headers = {
        "Content-Type": "application/json",
        "X-USER-EMAIL": localStorage.email,
        "X-USER-MONITORING-PROGRAM-CODE": localStorage.programCode,
        "X-USER-TOKEN": localStorage.accessToken,
        "Accept-Language": language,
      };

      Sentry.setUser({
        id: state.userId,
        username: state.name,
        email: state.email,
      });

      Sentry.setTag("tenant", state.tenantCode);
      Sentry.setTag("program", state.programCode);

      Sentry.setContext("tenant", {
        tenant_code: state.tenantCode,
        program_code: state.programCode,
      });
    },

    setAuthErrorMessage(state, error) {
      state.authErrorMessage = error.message;
    },
    setCurrentEpisodeInfo(state) {
      state.currentEpisodeId = localStorage.currentEpisodeId;
      state.measurementStartDate = localStorage.measurementStartDate;
      state.measurementEndDate = localStorage.measurementEndDate;
    },
    eulaAccepted(state) {
      localStorage.setItem("eulaAccepted", "true");
      state.eulaAccepted = true;
    },
    eulaNotAccepted(state) {
      localStorage.setItem("eulaAccepted", "false");
      state.eulaAccepted = false;
    },
    refreshSessionTimer(state) {
      state.session.warning = false;
      if (state.session.timeout) {
        clearTimeout(state.session.timeout);
      }
      state.session.timeout = setTimeout(() => {
        console.log("showing session warning");
        state.session.warning = true;
      }, SESSION_TIMEOUT);
      console.log(`session extended for ${SESSION_TIMEOUT} milliseconds`);
    },
    cableAction(state, action) {
      state.cableConnected = action === "connect";
    },

    addMeasurement(state, measurement) {
      if (!store.hasModule(`measurement${measurement.measurement_type_id}`)) {
        state.measurements.set(measurement.measurement_type_id, measurement);

        const {
          state: measurementState,
          getters: measurementGetters,
          mutations: measurementMutations,
        } = measurementStore;

        store.registerModule(`measurement${measurement.measurement_type_id}`, {
          namespaced: true,
          state: measurementState,
          getters: measurementGetters,
          mutations: measurementMutations,
        });
      }
    },
  },
});
