const translations = {
  en: {
    titles: {
      dashboard: "Dashboard",
      insights: "Insights",
      videoCalls: "Video Visits",
      faq: "Education",
      contact: "Contact",
      logout: "Logout",
      optional: "Optional Measurements",
      messenger: "Messenger",
      required: "Required Measurements",
      tips: "Helpful Tips & Hints",
      noFAQ: "No Frequently Asked Questions Found",
      faqPrompt: "Type keywords to find answers",
      question: "Question",
      notes: "Notes",
      concerningMeasurement: "You have entered a concerning measurement.",
      sessionWarning: "Your session is about to expire",
      tasks: "Tasks",
      overdue: "Overdue Tasks",
      due: "Today's Tasks",
      todaysActivities: "Today's Activities",
      completeOverdue: "Complete Overdue Tasks",
      scheduling: "Appointments",
      uncompleteSurvey: "Are you sure you are ready to submit the survey?",
    },
    prompts: {
      sessionWarning:
        "Are you still there? Click on the continue button to prevent a session timeout...",
      concerningMeasurement:
        "If you believe this value was entered in error, tap 'cancel' and please re-enter the value.",
      uncompleteSurvey:
        "After you have saved the survey, you cannot edit or retake it. Tap 'Cancel' to return to the survey.",
    },
    labels: {
      email: "Email",
      password: "Password",
      task: "task",
      dueToday: "due today",
      overdue: "overdue",
      and: "and",
      callWith: "Video Visit with",
      callScheduled: "Scheduled for",
      callDuration: "Estimated duration",
      addAttachment: "Click here to attach a file",
      completedOn: "Completed On",
      dueOn: "Due on",
    },
    placeholders: {
      writeMessage: "Write a message",
    },
    tasks: {
      attachment: {
        tips: "Attachment Tips",
      },
      category: {
        Watch: "Watch",
        Do: "Do",
        Read: "Read",
        Reminder: "Reminder",
        Answer: "Answer",
      },
    },
    footer: {
      support: "For technical support call: ",
      emergency: "For emergencies call: 911",
    },
    call: {
      status: {
        not_started: "Not Started",
        completed: "Completed",
        in_progress: "In Progress",
      },
    },
    contact: {
      title: "Contact Information",
      website: "Program Website",
      clinicPhone: "Clinic Phone",
      hospitalPhone: "Hospital Phone",
      afterHours: "After Hours Clinic Phone",
    },
    rules: {
      required: "Required",
      high: "Value is too high.",
      low: "Value is too low.",
      video_long: "Video is too long.",
      emailRequired: "E-mail is required.",
      emailValid: "E-mail must be valid.",
      numberValid: "Number must be valid.",
      integerValid: "Number must be an integer.",
      passwordRequired: "Password is required.",
    },
    error: {
      generic: "An error was encountered with your request. Please try again.",
      sessionExpired: "Your session has expired. Please log in again.",
      login: "Error with your email or password.",
      episode: "Episode is either inactive or monitoring end date has passed.",
      invalid_login: "Login Unsuccessful.",
      byod: "BYOD is not enabled. Please contact your administrator.",
      beforeMonitoringStartDate:
        "You will see measurements here beginning on your monitoring start date.",
      afterMonitoringEndDate: "Your monitoring episode has ended.",
      noExpectedMeasurements:
        "You will see measurements here when your coordinator assigns expected measurements.",
      noSubmittedMeasurements:
        "You will see measurement data here when you start inputting measurements.",
      tasks: "No tasks due today.",
    },
    buttons: {
      save: "Save",
      submit: "Submit",
      update: "Update",
      cancel: "Cancel",
      record: "RECORD",
      close: "CLOSE",
      back: "Back",
      join: "Join",
      accept: "Accept",
      login: "Login",
      continue: "Continue",
      forgotPassword: "Forgot Password?",
      complete: "COMPLETE",
    },
    scheduling: {
      header: "Appointments",
      empty: "No Appointments at the time",
      upcomingAppointments: "Upcoming Appointments",
      emptyUpcomingAppointments: "No Upcoming Appointments",
      pastAppointments: "Past Appointments",
      emptyPastAppointments: "No Past Appointmetns",
    },
    highcharts: {
      shortMonths: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      weekdays: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
    },
  },
  es: {
    titles: {
      dashboard: "Tablero",
      insights: "Medidas Enviadas",
      videoCalls: "Video Visitas",
      faq: "Educacion",
      contact: "Contacto",
      logout: "Cerrar Sesión",
      optional: "Opcional Medidas",
      messenger: "Mensajes",
      required: "Medidas Requeridas",
      tips: "Consejos y sugerencias útiles",
      noFAQ: "No se encontraron preguntas frecuentes",
      faqPrompt: "Escriba palabras clave para encontrar respuestas",
      question: "Pregunta",
      notes: "Notas",
      concerningMeasurement: "Has ingresado una medición preocupante.",
      sessionWarning: "Tu sesión esta por expirar",
      tasks: "Tareas",
      overdue: "Tareas Atrasadas",
      due: "Tareas de Hoy",
      todaysActivities: "Actividades de Hoy",
      completeOverdue: "Completar Tareas Atrasadas",
      scheduling: "Citas",
      uncompleteSurvey:
        "¿Está seguro de que está listo para enviar su encuesta?",
    },
    prompts: {
      sessionWarning:
        "¿Aún estas ahí?, Haz click en el boton de continuar para prevenir el fin de la sesión...",
      concerningMeasurement:
        "Si cree que este valor se ingresó por error, toque 'cancelar' y vuelva a ingresar el valor.",
      uncompleteSurvey:
        'Una vez que haya guardado la encuesta, no podrá editarla ni volver a tomarla. Presione "Cancelar" para regresar a la encuesta.',
    },
    labels: {
      email: "Correo Electrónico",
      password: "Contraseña",
      dueToday: "para hoy",
      overdue: "atrasada",
      dueOn: "Antes del",
      callWith: "Video Llamada con",
      callScheduled: "Agendada para",
      callDuration: "Duración estimada",
      addAttachment: "Haz click aquí para subir un archivo",
      and: "y",
      completedOn: "Completado En",
      task: "tarea",
    },
    scheduling: {
      header: "Citas",
      empty: "No hay citas en este momento",
      upcomingAppointments: "Proximas Citas",
      emptyUpcomingAppointments: "No hay citas proximas",
      pastAppointments: "Citas Pasadas",
      emptyPastAppointments: "No hay citas pasadas",
    },
    placeholders: {
      writeMessage: "Escriba un mensaje",
    },
    rules: {
      required: "Requerido",
      high: "El valor es demasiado alto.",
      low: "El valor es demasiado bajo.",
      video_long: "El video es muy largo.",
      emailRequired: "Correo electronico es requerido.",
      emailValid: "El email debe ser válido.",
      numberValid: "El número debe ser válido.",
      integerValid: "El número debe ser entero.",
      passwordRequired: "Se requiere contraseña.",
    },
    error: {
      generic: "Se encontró un error con su solicitud. Inténtalo de nuevo.",
      login: "Error con correo o contraseña.",
      episode:
        "El episodio está inactivo o el tiempo de monitoreo ha finalizado.",
      invalid_login: "Inicio de sesión fallido.",
      byod: "BYOD no esta habilitado. Por favor contacta a tu administrador.",
      sessionExpired: "Tu sesión ha expirado. Porfavor inicia sesión denuevo.",
      beforeMonitoringStartDate:
        "Verá las mediciones aquí comenzando en la fecha de inicio de su monitoreo.",
      afterMonitoringEndDate: "Su episodio de seguimiento ha finalizado.",
      noExpectedMeasurements:
        "Verá las medidas aquí cuando su coordinador asigne las medidas esperadas.",
      noSubmittedMeasurements:
        "Verá los datos de medición aquí cuando comience a ingresar mediciones.",
      tasks: "No hay tareas para hoy.",
    },
    footer: {
      support: "Para soporte técnico llame al: ",
      emergency: "Para emergencias llame al 911",
    },
    call: {
      status: {
        not_started: "No Ha Iniciado",
        completed: "Completa",
        in_progress: "En Progreso",
      },
    },
    contact: {
      title: "Información de contacto",
      website: "Sitio web del programa",
      clinicPhone: "Teléfono de la clínica",
      hospitalPhone: "Teléfono del hospital",
      afterHours: "Teléfono de la clínica después de horas",
    },
    buttons: {
      save: "Guardar",
      submit: "Enviar",
      update: "Actualizar",
      cancel: "Cancelar",
      record: "Grabar",
      close: "Cerrar",
      back: "Atrás",
      accept: "Acceptar",
      login: "Iniciar Sesión",
      continue: "Continuar",
      complete: "COMPLETAR",
      forgotPassword: "¿Olvidaste tu contraseña?",
    },
    tasks: {
      attachment: {
        tips: "Consejos para Adjuntos",
      },
      category: {
        Watch: "Ver",
        Do: "Hacer",
        Read: "Leer",
        Reminder: "Recordatorio",
        Answer: "Responder",
      },
    },
    highcharts: {
      months: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      shortMonths: [
        "Ene.",
        "Feb.",
        "Mar.",
        "Abr.",
        "May.",
        "Jun.",
        "Jul.",
        "Ago.",
        "Sep.",
        "Oct.",
        "Nov.",
        "Dic.",
      ],
      weekdays: [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
      ],
    },
  },
};

export default translations;
