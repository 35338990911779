<template>
  <div>
    <LoadingOverlay :loading="processing" />
    <v-form ref="form" v-model="form" class="mt-6" @submit.prevent="submit">
      <v-dialog v-model="displayConcern" persistent max-width="500px">
        <v-card class="text-center pa-4">
          <v-card-title style="hyphens: none" class="text-wrap">
            {{ $t("titles.concerningMeasurement") }}
          </v-card-title>

          <v-card-text class="text-medium-emphasis">
            <p>
              {{ settings.concerning_measurement_alert }}
            </p>
            <p class="mt-4">
              {{ $t("prompts.concerningMeasurement") }}
            </p>
          </v-card-text>

          <v-card-actions class="d-flex justify-center">
            <v-btn variant="text" size="large" @click="edit">
              {{ $t("buttons.cancel") }}
            </v-btn>
            <v-btn
              v-if="existingMeasurement"
              variant="text"
              size="large"
              @click="confirmUpdate"
            >
              {{ $t("buttons.update") }}
            </v-btn>
            <v-btn
              v-else
              variant="text"
              size="large"
              @click="confirmSubmission"
            >
              {{ $t("buttons.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <MeasurementTypeComponent
        v-for="(mtc, i) in sortedMeasurementTypeComponents"
        :key="i"
        :mtc="mtc"
        :measurement-type-id="measurementTypeId"
        :existing-measurement="existingMeasurement"
        :current-existing-measurements="currentExistingMeasurements"
        :on-has-alerts="onHasAlerts"
      />

      <FileInput
        v-if="showFileInput(expectedMeasurement)"
        :label="$t('labels.addAttachment')"
        :accept="getAcceptedFileTypes(expectedMeasurement)"
        :measurement-type-id="measurementTypeId"
        :required="isPhotoOrVideoMeasurement(expectedMeasurement)"
      />

      <Notes
        v-if="expectedMeasurement.allow_note == true"
        :existing-measurement="existingMeasurement"
        :measurement-type-id="measurementTypeId"
        @notes-updated="onNotesUpdated"
      />

      <div
        v-if="expectedMeasurement.measurement_tips"
        class="text-primary tips"
      >
        <em class="d-flex text-primary">
          <v-icon icon="mdi-information" color="primary" />
          {{ $t("titles.tips") }} :
        </em>

        <em class="d-flex text-primary">
          {{ expectedMeasurement.measurement_tips }}
        </em>
      </div>
      <div
        v-if="expectedMeasurement.attachment_tips"
        class="text-primary tips"
        dark
      >
        <em>
          <i style="color: #3b86df" class="mr-2 fa-solid fa-circle-info" />
          {{ $t("tasks.attachment.tips") }} :
          {{ expectedMeasurement.attachment_tips }}
        </em>
      </div>

      <v-spacer />
      <v-btn
        block
        color="primary"
        :disabled="!form"
        class="white-text mt-4"
        type="submit"
        data-cy="submit-measurement-btn"
        data-testid="submit-measurement-btn"
      >
        {{ existingMeasurement ? $t("buttons.update") : $t("buttons.submit") }}
      </v-btn>
    </v-form>
  </div>
</template>

<script>
  import { mapState } from "pinia";
  import { useSettingsStore } from "@/store/pinia/settings";
  import { useMeasurementFieldsStore } from "@/store/pinia/measurementFields";
  import { useCalendarDatesStore } from "@/store/pinia/calendarDates";
  import { format, parse } from "date-fns";
  import { v4 as uuidv4 } from "uuid";
  import axios from "axios";

  import MeasurementTypeComponent from "@/components/MeasurementTypeComponent.vue";
  import Notes from "@/components/measurement_form_fields/Notes.vue";
  import FileInput from "@/components/measurement_form_fields/FileInput.vue";
  import LoadingOverlay from "./LoadingOverlay.vue";

  export default {
    components: {
      Notes,
      FileInput,
      MeasurementTypeComponent,
      LoadingOverlay,
    },

    props: {
      expectedMeasurement: { type: Object, default: null },
      existingMeasurement: { type: Object, default: null },
      existingMeasurementIdx: { type: Number, default: null },
      existingMeasurements: { type: Object, default: null },
    },

    emits: ["hide-form", "display-form", "updateExistingMeasurements"],
    data() {
      return {
        form: false,
        attachmentValue: null,
        notes: "",
        processing: false,
        displayConcern: false,
        measurementTypeId: this.expectedMeasurement.measurement_type_id,
        currentEpisodeId: this.$store.state.currentEpisodeId,
      };
    },

    computed: {
      ...mapState(useSettingsStore, ["settings"]),
      ...mapState(useMeasurementFieldsStore, ["fields"]),
      ...mapState(useCalendarDatesStore, { date: "startDate" }),
      attachmentField() {
        return this.$refs.form.items.find(({ id }) => id === "attachment");
      },

      currentExistingMeasurements() {
        if (this.existingMeasurements) {
          return this.existingMeasurements[this.measurementTypeId] || [];
        }
        return [];
      },

      sortedMeasurementTypeComponents() {
        return this.expectedMeasurement.measurement_type_components
          .slice(0)
          .sort((a, b) => a.sequence - b.sequence);
      },

      measurementCount() {
        return this.currentExistingMeasurements.length;
      },

      measurementNameLength() {
        return this.expectedMeasurement.measurement_type_readable_name
          .split(" ")
          .sort(function (a, b) {
            return b.length - a.length;
          })[0].length;
      },
    },

    watch: {
      currentExistingMeasurements(measurements) {
        const values = measurements.reduce(
          (acc, measurement) => [...acc, ...measurement.measurement_values],
          []
        );
        const concerning = values.reduce(
          (acc, value) =>
            acc ||
            value.is_baseline_concerning ||
            value.is_change_threshold_concerning ||
            value.is_threshold_concerning,
          false
        );

        this.hasAlerts = concerning;
      },
    },

    async mounted() {
      if (this.attachmentField) this.attachmentField.validate();
      if (this.existingMeasurement) {
        await this.$refs.form.validate();
      }
    },

    methods: {
      edit() {
        this.processing = false;
        this.displayConcern = false;
      },

      resetForm() {
        const fieldRefs = this.fields[this.measurementTypeId] || [];

        fieldRefs.forEach((fieldRef) => {
          fieldRef.fieldValue = "";
        });

        this.$refs.form.reset();
        this.$emit("hide-form");
        this.processing = false;
      },

      submit(e) {
        const measurementValues = [];
        const fieldRefs = this.fields[this.measurementTypeId] || [];
        let submitMethod = this.confirmSubmission;
        this.attachmentValue = e?.target["attachment"]?.files[0];

        fieldRefs.forEach((field, idx) => {
          this.processing = true;

          if (field.concerning) {
            this.displayConcern = true;
          }

          if (field.fieldValue || field.fieldValue === 0) {
            if (this.existingMeasurement) {
              measurementValues.push({
                ...this.existingMeasurement.measurement_values[idx],
                data: field.fieldValue,
              });
            } else {
              measurementValues.push({
                data: field.fieldValue,
                database_units: field.databaseUnits,
                measurement_type_component_id: field.measurementTypeComponentId,
              });
            }
          }
        });

        const date = format(this.date, "yyyy-MM-dd");
        const time = format(new Date(), "HH:mm");
        const timestamp = parse(
          `${date} ${time}`,
          "yyyy-MM-dd HH:mm",
          new Date()
        );

        this.payload = {
          user_id: this.$store.state.userId,
          timestamp: timestamp.toISOString(),
          device_uuid: this.currentEpisodeId + "-" + this.$store.state.email,
          device_measurement_uuid: uuidv4(),
          notes: this.notes,
          measurement_type_id: this.expectedMeasurement.measurement_type_id,
          measurement_values: measurementValues.map((mv) => ({
            ...mv,
            source: "PWA",
          })),
        };

        if (this.existingMeasurement) {
          this.payload.timestamp = this.existingMeasurement.timestamp;
          submitMethod = this.confirmUpdate;
        }

        if (!this.displayConcern) {
          submitMethod();
        }
      },

      confirmSubmission() {
        this.displayConcern = false;

        return this.$api
          .post(
            `self_reporter/episodes/${this.currentEpisodeId}/measurements`,
            this.payload
          )
          .then((response) => {
            if (response.status == 200) {
              const measurements =
                this.existingMeasurements[this.measurementTypeId] || [];
              measurements.push(response.data);

              this.$emit(
                "updateExistingMeasurements",
                this.measurementTypeId,
                measurements
              );
            }

            return response;
          })
          .then((response) => {
            this.createAttachment(response);

            this.resetForm();
          })
          .catch((e) => {
            this.errorMessage = e.response;
          });
      },

      confirmUpdate() {
        this.displayConcern = false;

        return this.$api
          .put(
            `self_reporter/episodes/${this.currentEpisodeId}/measurements/${this.existingMeasurement.id}`,
            this.payload
          )
          .then((response) => {
            const measurements = [
              ...this.existingMeasurements[this.measurementTypeId],
            ];
            measurements.splice(this.existingMeasurementIdx, 1, response.data);

            this.$emit(
              "updateExistingMeasurements",
              this.measurementTypeId,
              measurements
            );
            return response;
          })
          .then((response) => {
            this.createAttachment(response);
            this.resetForm();
          })
          .catch((e) => {
            this.errorMessage = e.response;
          });
      },

      createAttachment(response) {
        const file = this.attachmentValue;

        const id = response.data && response.data.id;

        if (!file || !id) return;

        this.processing = true;
        this.$emit("display-form");
        let attachmentUUID = null;

        return this.$api
          .post(`/self_reporter/attachments`, {
            original_filename: file.name,
            content_type: file.type,
            attachable_id: id,
            attachable_type: "Measurement",
          })
          .then(({ data: { resources, uuid } }) => {
            attachmentUUID = uuid;
            if (resources) {
              const { url, fields } = resources.data;
              const formData = new FormData();

              Object.keys(fields).forEach((key) => {
                formData.append(key, fields[key]);
              });

              formData.append("file", file);

              return axios.post(url, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              });
            }
          })
          .catch((e) => {
            this.errorMessage = e.response;
            if (attachmentUUID) {
              return this.$api.delete(
                `/self_reporter/attachments/${attachmentUUID}`
              );
            }
          })
          .finally(() => {
            this.processing = false;
            this.$emit("hide-form");
          });
      },

      showFileInput(expectedMeasurement) {
        return (
          this.isPhotoOrVideoMeasurement(expectedMeasurement) ||
          expectedMeasurement.allow_attachment ||
          expectedMeasurement.allow_attachment_video
        );
      },

      onHasAlerts(value) {
        this.hasAlerts = value;
      },

      isPhotoOrVideoMeasurement(expectedMeasurement) {
        const { measurement_type_name: typeName } = expectedMeasurement;

        return ["video", "photo", "daily_photo_or_video"].includes(typeName);
      },

      getAcceptedFileTypes(expectedMeasurement) {
        if (this.isPhotoOrVideoMeasurement(expectedMeasurement)) {
          const types = {
            video: "video/mp4,video/quicktime",
            photo: "image/jpeg,image/png",
            daily_photo_or_video:
              "video/mp4,video/quicktime,image/jpeg,image/png",
          };

          return types[expectedMeasurement.measurement_type_name];
        } else {
          let accepted = "";

          if (expectedMeasurement.allow_attachment) {
            accepted += "image/jpeg,image/png,";
          }
          if (expectedMeasurement.allow_attachment_video) {
            accepted += "video/mp4,video/quicktime";
          }

          return accepted;
        }
      },

      onNotesUpdated(value) {
        this.notes = value;
      },
    },
  };
</script>
